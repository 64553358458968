import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from 'timeago.js'

import './style.less'
import noticon from '../../../asset/images/blacknoticon.svg';
import notifcationreminder from '../../../asset/images/notificationreminder.svg'
import timecircle from '../../../asset/images/timecirclegray.svg';

import { getSingleJob } from '../../../api/createjob'
import { callNotificationListApi, callNotificationCountApi, callNotificationRead } from '../../../api/notifications';

const Notifications = () => {
  const [notification, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [notificationUnRead, setNotificationUnRead] = useState();
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
    const objInfoId = useSelector((state) => state?.login?.profileInfo.id);
  useEffect(() => {
    allNotification();
    callNoticationCount()
  }, [page]);

  const callNoticationCount = async () => {
    const rep = await callNotificationCountApi();
    setNotificationUnRead(rep?.data?.data?.count);
  }

  const allNotification = async () => {
    const getnotification = await callNotificationListApi(page);
    if (page == 1)
      setNotifications(getnotification?.data?.data)
    else {
      const val = [...notification, ...getnotification?.data?.data]
      setNotifications(val);
    }
  };

  const onScroll = () => {
    const menuList = document.getElementsByClassName("ant-dropdown-menu")
    if (menuList?.length) {
      const { scrollTop, scrollHeight, clientHeight } = menuList[0];

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (notification?.length % 10 == 0) {
          setPage((notification.length / 10) + 1);
        }
      }
    }
  };

  const handleReadNotification = async (id) => {
    const resp = await callNotificationRead(id);
    if(resp?.status==200)
    allNotification();
  }
  const handleContrctorNavigation = async (message1) => {
    const item = await getSingleJob(message1?.linkId);
    if (item?.response?.status >= 400)
      message.error("Job not found")
    else if (message1?.type == "ACCEPTED_APPLICATION" && item?.data?.data?.jobStatus == "Pending")
      message.error("Job Cancelled")
      // else if(message1?.type == "JOB_CREATED" && item?.data?.data?.appliedBy.includes(objInfoId))
      // navigate(`/contjobsdata?id=${message1?.linkId}&screen=2`, { state: { type: message1?.type } })
      else if(message1?.type == "JOB_CREATED" && item?.data?.data?.assignedTo?._id==objInfoId)
      navigate(`/contjobsdata?id=${message1?.linkId}&screen=2`, { state: { type: message1?.type } })
      else if(  item?.data?.data?.assignedTo!=null && item?.data?.data?.assignedTo?._id!=objInfoId)
      message.error("You're not Authorized to view this job.")
    else if(message1?.type == "JOB_CREATED"&& item?.data?.data?.assignedTo==null)
    navigate(`/contjobsdata?id=${message1?.linkId}&screen=1`, { state: { type: message1?.type } })
    else
      navigate(`/contjobsdata?id=${message1?.linkId}&screen=2`, { state: { type: message1?.type } })

  }
  const handleClientNavigation = async (message1) => {
    const item = await getSingleJob(message1?.linkId);
    if (item?.response?.status >= 400)
      message.error("Job not found")
    else
      navigate(`/jobsdata?id=${message1?.linkId}`)
  }

  const items = [
    ...notification?.map((message1) => {
      return {
        label: (
          <div
            className={
              !message1?.read
                ? "row notification-row-main mx-0"
                : "row notification-row-main-no  mx-0"
            }
            onClick={() => {
              if (!message1?.read)
                handleReadNotification(message1?._id);
              if (localStorage.getItem('role') == "0")
                handleClientNavigation(message1)
              else
                handleContrctorNavigation(message1)
            }}
          >
            <div className="d-flex notificationinfo">
              {role == "0" && <div className="notimg"><img src={message1?.sender?.profileImage?.url ? message1?.sender?.profileImage?.url : notifcationreminder} style={{}} /></div>}
              <div className="notinfo">
                <div className="position-relative col-12">
                  <p>{message1?.message}</p>
                </div>
                <div className="position-relative text-end col-12 timeinfo">
                  <div className="mb-0 mt-4"><img src={timecircle} className="mr-1" />{format(message1?.createdAt)}</div>
                </div>
              </div>
            </div>
          </div>
        ),
        key: "0",
        className: message1?.read ? "read" : "unread",
      };
    }),
  ];
  return (
    <>
      <Dropdown
        menu={{
          items,
          itemRef: `${listInnerRef}`,
          onScroll,
        }}
        trigger={["click"]}
        overlayClassName="custom-dropdown custom-dropdown-notifications"
        style={{ height: "300px" }}
        ref={listInnerRef}
      >
        <Button
          className="position-relative notification-btn bg-transparent border-0 p-0 shadow-none"
        >
          <img
            src={noticon}
            alt=""
            className="img-fluid"
          />
          {notificationUnRead > 0 && <span className="bedge-icon" >{notificationUnRead}</span>}
        </Button>
      </Dropdown>
    </>
  );
};

export default Notifications;