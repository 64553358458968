import React, { useState, useEffect } from "react";
import Button from 'antd/es/button';
import { Slider } from "antd";
import { GoogleMap, Marker, useJsApiLoader, Circle } from '@react-google-maps/api';

const TravelRadius = ({ handleAddCount, save, details }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE
  })
  const [dim, setDim] = useState(0);
  const [dim1, setDim1] = useState(50);
  const [center, setCenter] = useState()
  const mapContainerStyle = {
    width: "83%",
    height: " 59vh",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "1.25rem",
    background: "#fff",
    margin: "auto"
  };

  useEffect(() => {
    setCenter({
      lat: Number(details?.location?.lat) || +Number(details?.location?.lat),
      lng: Number(details?.location?.long) || +Number(details?.location?.long)
    })
  }, [details])

  const onChange = (newValue) => {
    let dummy = newValue + 100;
    setDim(newValue);
    setDim1(dummy * 5)
  };

  const options = {
    strokeColor: "#19B792", strokeOpacity: 0.5, strokeWeight: 3, fillColor: "#19B7921A", fillOpacity: 1, clickable: false, draggable: false, editable: false, visible: true, radius: dim1, zIndex: 1,
  };

  const options1 = {
    strokeColor: "#19B792",
    strokeOpacity: 0.5,
    strokeWeight: 3,
    fillColor: "#19B7921A",
    fillOpacity: 0.05,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: dim1 + 120,
    zIndex: 1,
  };

  const handleAddRadius = () => {
    handleAddCount(2);
    save({ currentRadius: dim })
  }

  return (
    <>
      <div className="center-container justify-content-center  profiledetails"  >
        <div className="heading">Radius of Travel From Current Location</div>
        <div >
          <div className="k_map">
            {dim}km</div>
          <div className="k-triangle"></div>
          {details?.location && isLoaded &&

            <GoogleMap
              id="circle-example"
              mapContainerStyle={mapContainerStyle}
              defaultOptions={{
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
                draggable: false,
              }}
              zoom={12.6}
              center={center}
            >
              <Marker position={{ lat: center?.lat, lng: center?.lng }} />
              {dim > 0 &&
                <>
                  <Circle
                    center={center}
                    options={options}
                  ></Circle>
                  <Circle
                    center={center}
                    options={options1}
                  ></Circle>
                </>
              }

              <div className="km_map">{dim} km</div>

              <div >
                <Slider
                  className="simple_slider"
                  railStyle={{
                    borderRadius: "1.25rem",
                    background: "#CCECE4",
                    height: " 0.75rem",
                    flexShrink: 0
                  }}
                  trackStyle={{

                    height: "0.75rem",
                    borderRadius: "1.25rem",
                    background: "#0FF4BD"
                  }}
                  handleStyle={{
                    background: "#FFF",
                    borderRadius: "50%",
                    border: "2px solid #0FF4BD",
                    width: " 23px",
                    height: "23px",
                    zIndex: "1",
                    top: "-1px",

                  }}
                  min={0}
                  max={200}
                  step={10}
                  value={dim}
                  tooltip={{
                    open: false,
                  }}
                  style={{ top: '79%', right: "40%", width: "23.328%", position: "absolute" }}
                  onChange={onChange}
                />
              </div>
            </GoogleMap>
          }
          <div>
            <Button
              style={{ position: "absolute", bottom: '1%', right: "37.5%" }}
              className={dim <= 0 ? "map-submitbtnd" : "map-submitbtn"}
              onClick={handleAddRadius}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelRadius;