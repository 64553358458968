import React, { useEffect, useState, useRef } from 'react';
import { Button, message, Rate } from 'antd';
import Modal from 'antd/es/modal/Modal';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CreateJob from '../../../components/CreateJob';
import ModalSingle from '../../../components/Modal';
import PopUpModal from '../../../components/PopUpModal';
import RatingModal from '../../../components/RatingModal';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import SpinWrapper from '../../../components/wrapper/SpinWrapper';
import IsLiftRequiredInfo from './IsLiftRequiredInfo';
import LocationInfo from './LocationInfo';
import MakePayment from './MakePayment';
import SubCategoryInfo from './SubCategoryInfo';
import ViewAll from './ViewAll';
import boldstar from '../../../asset/images/boldstar.svg';
import calendergreen from '../../../asset/images/calendargreen.svg';
import crossicon from '../../../asset/images/crossicon.svg';
import declinere from '../../../asset/images/declinejobre.svg';
import Edit from '../../../asset/images/Edit.svg';
import liftrequiredbtn from '../../../asset/images/liftrequiredgreenbtn.svg';
import locationgreen from '../../../asset/images/locationgreen.svg';
import locationred from '../../../asset/images/locationred.svg';
import notrequiredliftbtn from '../../../asset/images/notrequiredliftbtn.svg';
import Star from '../../../asset/images/Star.svg';
import timecircle from '../../../asset/images/timecircle.svg';
import Breadcrumbs from '../../../common/BreadCrumbs';
import { pushBreadCrums } from '../../../redux/slices/breadcrum';
import { showLoader } from '../../../redux/slices/common';
import { setCreateObject } from '../../../redux/slices/createinfo';
import { setCount } from '../../../redux/slices/login';
import AddLocation from '../createjob/AddLocation';
import PostJobPopup from '../createjob/PostJobPopup';

import { addRating } from '../../../api/clientjobs';
import {
  locationSaveApi,
  getJobCost,
  callPendingContractorListApi,
  acceptJobApi,
  declineJobApi,
  getSingleJob,
  updateJobApi,
  deleteJobPostApi,
  cancelJobAppointMentApi,
  locationListApi,
} from '../../../api/createjob';

const InfoData = ({ item }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [feedBack, setFeedBack] = useState('');
  const [singleContractor, setSingleContractor] = useState({});
  const [singleJobInfo, setSingleJobInfo] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [jobObj, setJobObj] = useState({});
  const [rate, setRate] = useState();
  const [reviewModal, setReviewModal] = useState(false);
  const [isDecline, setIsDecline] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [approxCost, setApproxCost] = useState('');
  const [approxCostAlertText,setApproxCostAlertText]=useState();
  const [postJobModal, setPostJobModal] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [expenseCost, setExpenseCost] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [imageView, setImageView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [showRatings, setShowRatings] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [address, setAddress] = useState();
  const [locations, setLocations] = useState([]);
  const [flag, setFlag] = useState(false);
  const [editModal,setShowEditModal]=useState(false)
  const queryParam = new URLSearchParams(location.search);
  const [contId, setContId] = useState();
  const id = queryParam.get('id');
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowPopUp(false);
  };
  const listStyle = {
    overflowY: 'scroll',
    height: 'calc(100vh - 230px)',
  };
  const handleDecline = () => {
    setOpenModal(true);
    setShowPopUp(false);
    setIsDecline(true);
  };
  const handleAccept = async (contid) => {
    setShowPopUp(false);
    const resp = await acceptJobApi(contid, id);
    callContractorApiPending();
    if (resp?.status == 200) navigate('/jobs');
  };
  const callLocationListApi = async () => {
    const resp = await locationListApi();
    if (resp?.status == 200) {
      setLocations([...resp?.data?.data]);
    }
  };
  const saveLocationList = async () => {
    setOpenLocationModal(false);
    dispatch(showLoader(true));
    const resp = await locationSaveApi(address);
    if (resp?.status == 200) {
      setAddress(resp?.data?.data);
      callLocationListApi();
      setFlag(true);
    }
    dispatch(showLoader(false));
  };
  const callContractorApiPending = async () => {
    dispatch(showLoader(true));
    const resp = await callPendingContractorListApi(id, singleJobInfo?.assignedTo?._id);
    setContractorList(resp?.data?.data);
    dispatch(showLoader(false));
  };
  const callSingleJobApi = async (id) => {
    dispatch(showLoader(true));
    const resp = await getSingleJob(id);
    setSingleJobInfo(resp?.data?.data);
    setExpenseCost(resp?.data?.data?.expensesCost);
    setTotalAmount(resp?.data?.data?.totalAmount);
    dispatch(showLoader(false));
  };
  const handleEditJob = () => {
    setShowEditModal(false);
    setIsEdit(true);
  };
  const breadCrumFunc = () => {
    if (isEdit) {
      setIsEdit(false);
      dispatch(setCreateObject({}));
    }
  };

  const handleYes = async () => {
    setOpenModal(false);
    dispatch(showLoader(true));
    let resp = '';
    if (isDecline) {
      const resp = await declineJobApi(singleJobInfo?._id, singleContractor?.appliedBy?._id);
      dispatch(showLoader(false));
      if (resp?.status == 200) {
        message.success('Job has been declined sucessfully');
        navigate('/jobs', { state: { activeTab: 1 } });
      }
    } else {
      if (singleJobInfo?.jobStatus == 'Pending') resp = await deleteJobPostApi(singleJobInfo._id, feedBack);
      else resp = await cancelJobAppointMentApi(singleJobInfo._id, singleJobInfo?.assignedTo?._id, feedBack);

      dispatch(showLoader(false));
      if (resp?.status == 200) {
        if (singleJobInfo?.jobStatus == 'Pending') {
          message.success('Job has been deleted successfully!');
          navigate('/jobs', { state: { activeTab: 1 } });
        } else {
          message.success('Job request cancelled successfully! ');
          navigate('/jobs', { state: { activeTab: 2 } });
        }
      }
    }
  };

  const handleSaveEditJob = async () => {
    dispatch(showLoader(true));
    const val = jobObj?.jobStartDate + ' ' + jobObj?.jobStartTime;
    const utcTime = new Date(val).toISOString();
    const newvalue = { ...jobObj, jobStartUtcTime: utcTime };
    if (singleJobInfo?.jobStatus == 'Pending') {
      const resp = await updateJobApi(singleJobInfo?._id, { ...newvalue });
      if (resp?.data?.type == 'success') message.success(resp?.data?.message);
      setIsEdit(false);
    } else {
      const val = { jobStartDate: jobObj.jobStartDate, jobStartTime: jobObj.jobStartTime };
      const resp = await updateJobApi(singleJobInfo?._id, val);
      if (resp?.data?.type == 'success') message.success(resp?.data?.message);
      setIsEdit(false);
    }
    dispatch(showLoader(false));
    dispatch(setCreateObject({}));
  };

  const handleRating = () => {
    addRating(rate, singleJobInfo?.assignedTo?._id, singleJobInfo?._id);
    setReviewModal(false);
    setReviewSubmitted(true);
  };

  const handleClickPendingMessage=()=>{
    let job=[{_id:"",jobStartDate:"",jobStartTime:""}];
    let location=[];
    let category=[{_id:"",name:""}]
    job[0]._id=singleJobInfo?._id;
   // job[0].locationId=singleJobInfo?.locationId;
    job[0].jobStartDate=singleJobInfo?.jobStartDate;
    job[0].jobStartTime=singleJobInfo?.jobStartTime;
    category[0]._id=singleJobInfo?.categoryId?._id;
    category[0].name=singleJobInfo?.categoryId?.name;
    location[0]=singleJobInfo?.locationId;
    let userId={_id:""};
   userId._id=singleJobInfo?.assignedTo?._id;
    navigate('/messages', {
      state: {
        val: {
          firstName: singleContractor?.appliedBy?.firstName,
          lastName: singleContractor?.appliedBy?.lastName,
          profileImage: singleContractor?.appliedBy?.profileImage,
          _id: singleContractor?.appliedBy?._id,
          job:job,
          category:category,
          location:location,
          userId:userId
        },
      },
    });
  }
  const handleClickMessage = () => {
  let job=[{_id:"",jobStartDate:"",jobStartTime:""}];
  let location=[];
  let category=[{_id:"",name:""}]
  job[0]._id=singleJobInfo?._id;
 // job[0].locationId=singleJobInfo?.locationId;
  job[0].jobStartDate=singleJobInfo?.jobStartDate;
  job[0].jobStartTime=singleJobInfo?.jobStartTime;
  category[0]._id=singleJobInfo?.categoryId?._id;
  category[0].name=singleJobInfo?.categoryId?.name;
  location[0]=singleJobInfo?.locationId;
  let userId={_id:""};
   userId._id=singleJobInfo?.assignedTo?._id;
    navigate('/messages', {
      state: {
        val: {
          firstName: singleJobInfo?.assignedTo?.firstName,
          lastName: singleJobInfo?.assignedTo?.lastName,
          profileImage: singleJobInfo?.assignedTo?.profileImage,
          _id: singleJobInfo?.assignedTo?._id,
          job:job,
          category:category,
          location:location,
          userId:userId
        },
      },
    });
    dispatch(setCount(3));
  };

  useEffect(() => {
    if (!isEdit) {
      dispatch(
        pushBreadCrums(
          [
            {
              title: 'Jobs',
              route: '/jobs',
              activeTab:
                singleJobInfo?.jobStatus == 'Pending'
                  ? 1
                  : singleJobInfo?.jobStatus == 'UpComing' || singleJobInfo?.jobStatus == 'OnGoing'
                  ? 2
                  : 3,
              tabname: 'Jobs',
              breadCrumFunc: breadCrumFunc,
            },
          ].concat({
            title: 'Job Details',
            route: `/jobsdata?id=${id}`,
            breadCrumFunc,
          })
        )
      );
    } else {
      dispatch(
        pushBreadCrums(
          [
            {
              title: 'Jobs',
              route: '/jobs',
              tabname: 'Jobs',
              activeTab:
                singleJobInfo?.jobStatus == 'Pending'
                  ? 1
                  : singleJobInfo?.jobStatus == 'UpComing' || singleJobInfo?.jobStatus == 'OnGoing'
                  ? 2
                  : 3,
              breadCrumFunc: breadCrumFunc,
            },
          ]
            .concat({
              title: 'Job Details',
              route: `/jobsdata?id=${id}`,
              breadCrumFunc,
            })
            .concat({ title: 'Edit Job Post', route: '' })
        )
      );
      callLocationListApi();
    }
  }, [isEdit, singleJobInfo?.jobStatus]);

  useEffect(() => {
    if (!isEdit) {
      callContractorApiPending();
      callSingleJobApi(id);
    }
  }, [isEdit]);
  useEffect(() => {
    callSingleJobApi(id);
  }, [id]);
  const handleCreateJob = () => {
    handleSaveEditJob();
  };
  const getApproxCost = async () => {
    dispatch(showLoader(true));
    const resp = await getJobCost(jobObj);
    setApproxCost(resp?.data?.data?.totalAmount);
    setApproxCostAlertText(resp?.data?.data?.approxCostAlertText);
    setPostJobModal(true);
    dispatch(showLoader(false));
  };

  const getClass = (jobStatus) => {
    if (jobStatus == 'UpComing') return 'statusup statusinfo';
    if (jobStatus == 'OnGoing') return 'statuson statusinfo';
    if (jobStatus == 'Completed') return 'statuspast statusinfo';
    else return 'statuspayment statusinfo';
  };
  return (
    <DashBoardWrapper>
      <>
        {!makePayment ? (
          <>
            <div className="d-flex justify-content-between">
              <div className="">
                <Breadcrumbs />
              </div>
              {singleJobInfo?.jobStatus == 'Pending' && (
                <div
                  className="pendinginfo"
                  style={{
                    color: 'black',
                    width: '109px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '22px',
                    backgroundColor: 'rgb(255, 217, 18)',
                    alignItems: 'center',
                  }}
                >
                  Pending
                </div>
              )}
            </div>
            <SpinWrapper>
              {!isEdit ? (
                <>
                  <div className="singlejobinfo" style={listStyle}>
                    {contractorList?.length > 0 && singleJobInfo?.jobStatus == 'Pending' && (
                      <>
                        <div className="contractorheading d-flex justify-content-between ">
                          <div
                            className="contractortitle"
                            style={{ color: '#505150', fontWeight: '500', fontSize: '16px' }}
                          >
                            Contractor Applications
                          </div>
                          <div
                            className="viewallbtn"
                            style={{ color: '#0FF4BD', fontWeight: '500', cursor: 'pointer' }}
                            onClick={() => {
                              setViewAll(true);
                            }}
                          >
                            View All
                          </div>
                        </div>
                        <div className="contractorslist">
                          {contractorList?.map((item) => {
                            return (
                              <div
                                className="singlecontractor"
                                onClick={() => {
                                  setShowPopUp(true);
                                  setSingleContractor(item);
                                }}
                              >
                                <div className="userimg">
                                  <img src={item?.appliedBy?.profileImage?.url} />
                                </div>
                                <div className="contractorname my-2">
                                  {item?.appliedBy?.firstName} {item?.appliedBy?.lastName}
                                </div>
                                <div
                                  className="ratings d-flex justify-content-center"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setShowRatings(true);
                                    setContId(item?.appliedBy?._id);
                                  }}
                                >
                                  <div className="staricon mr-1">
                                    <img src={Star} />
                                  </div>
                                  <div className="ratingnumber">
                                    {item?.appliedBy?.averageRating
                                      ? Math.abs(item?.appliedBy?.averageRating).toFixed(2)
                                      : '0.0'}
                                  </div>
                                  <div className="ratingnumber1 ml-1">{item?.appliedBy?.totalRating} Ratings</div>
                                </div>
                                <div className="messagebtn">Message</div>
                                <div className="declinebtn">Decline</div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    {singleJobInfo?.jobStatus != 'Pending' && (
                      <div className="d-flex justify-content-between">
                        <div className="contractorinfo d-flex">
                          <div className="contractorimage">
                            <img
                              src={singleJobInfo?.assignedTo?.profileImage?.url}
                              style={{ width: '85px', height: '85px' }}
                            />
                          </div>
                          <div className="contactorinfo ml-3 d-flex flex-column justify-content-between">
                            <div
                              className="contractorname "
                              style={{ color: 'black', fontWeight: '500', fontSize: '18px' }}
                            >
                              {singleJobInfo?.assignedTo?.firstName} {singleJobInfo?.assignedTo?.lastName}
                            </div>
                            <div
                              className="ratings"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setShowRatings(true);
                                setContId(singleJobInfo?.assignedTo?._id);
                              }}
                            >
                              {' '}
                              <div className="starimg d-flex">
                                <img src={boldstar} style={{ width: '30px', height: '20px' }} />
                                <span style={{ color: 'black', fontWeight: '600' }}>
                                  {contractorList[0]?.appliedBy?.averageRating
                                    ? Math.abs(contractorList[0]?.appliedBy?.averageRating).toFixed(2)
                                    : '0.0'}
                                </span>
                                <span style={{ color: '#A1A1AA', marginLeft: '3px' }}>
                                  (
                                  {contractorList[0]?.appliedBy?.totalRating
                                    ? contractorList[0]?.appliedBy?.totalRating
                                    : 0.0}{' '}
                                  reviews)
                                </span>{' '}
                              </div>
                            </div>
                            <div className="location d-flex">
                              <div className="locationicon ">
                                <img src={locationgreen} />
                              </div>
                              <span style={{ color: '#A1A1AA' }}>
                                {singleJobInfo?.assignedTo?.address} {singleJobInfo?.assignedTo?.city}{' '}
                                {singleJobInfo?.assignedTo?.province} {singleJobInfo?.assignedTo?.postalCode}
                              </span>
                            </div>
                          </div>
                        </div>
                        {singleJobInfo?.jobStatus && (
                          <div className={getClass(singleJobInfo?.jobStatus)}>
                            {singleJobInfo?.jobStatus == 'UpComing'
                              ? 'Upcoming'
                              : singleJobInfo?.jobStatus == 'OnGoing'
                              ? 'Ongoing'
                              : singleJobInfo?.jobStatus == 'Completed'
                              ? 'Completed'
                              : singleJobInfo?.jobStatus == 'Cancelled'
                              ? 'Cancelled'
                              : 'Payment Pending'}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="jobinfodetails pl-4">
                      <div className="row1 d-flex justify-content-between">
                        <div className="para1">
                          <div className="rowfirst mt-2 mr-2 mb-2" style={{ fontSize: '20px' }}>
                            {singleJobInfo?.locationId?.name}
                          </div>
                          <LocationInfo
                            locationred={locationred}
                            calendergreen={calendergreen}
                            singleJobInfo={singleJobInfo}
                            timecircle={timecircle}
                          />
                        </div>
                        {singleJobInfo?.approxCost && (
                          <div className="para2">
                            <div className="ammount">${Math.abs(singleJobInfo?.approxCost).toFixed(2)}</div>
                            <div className="approxcost" style={{ color: 'gray', fontSize: '12px', fontWeight: '400' }}>
                              Approx Cost
                            </div>
                          </div>
                        )}
                      </div>
                      {singleJobInfo?.jobStatus != 'OnGoing' &&
                        singleJobInfo?.jobStatus != 'Past' &&
                        singleJobInfo?.jobStatus != 'PendingPayment' &&
                        singleJobInfo?.jobStatus != 'Completed' &&
                        singleJobInfo?.jobStatus != 'Cancelled' && (
                          <Button
                            disabled={singleJobInfo?.isUpdateAllowed ? false : true}
                            className={
                              singleJobInfo?.isUpdateAllowed || singleJobInfo?.jobStatus != 'Pending'
                                ? 'editpost d-flex'
                                : 'editpost editpostd d-flex'
                            }
                            onClick={() => {
                              if (singleJobInfo?.jobStatus == 'Pending') setShowEditModal(true);
                              else {
                                handleEditJob();
                              }
                            }}
                          >
                            <div className="editimg">
                              <img src={Edit} />
                            </div>
                            <div className="editcontent">Edit Job</div>
                          </Button>
                        )}
                    </div>
                    <div className="my-3 headinginfo">Services</div>
                    <div className="services">
                      <div className="singleService">
                        <div className="img mr-2">
                          <img src={singleJobInfo?.categoryId?.categoryImage?.url} />
                        </div>
                        <div className="serviceinfo">{singleJobInfo?.categoryId?.name}</div>
                      </div>
                    </div>
                    <div className="headinginfo">Surface Applied To</div>
                    <SubCategoryInfo singleJobInfo={singleJobInfo} />
                    <div className="headinginfo">Lift Required</div>
                    <IsLiftRequiredInfo
                      singleJobInfo={singleJobInfo}
                      liftrequiredbtn={liftrequiredbtn}
                      notrequiredliftbtn={notrequiredliftbtn}
                    />
                    {singleJobInfo?.additionalNotes && (
                      <>
                        <div className="my-3 headinginfo">Addditional Note</div>
                        <div className="additionalnote">{singleJobInfo?.additionalNotes}</div>
                      </>
                    )}

                    {singleJobInfo?.jobStatus == 'PendingPayment' ||
                      (singleJobInfo?.jobStatus == 'Completed' && (
                        <img
                          src={singleJobInfo?.completeJobAttachments[0]?.url}
                          style={{ width: '150px', height: '150px', borderRadius: '15px', marginBottom: '20px' }}
                          onClick={() => {
                            setImageView(true);
                          }}
                        />
                      ))}
                    {singleJobInfo?.completeJobNotes &&
                      (singleJobInfo?.jobStatus == 'Completed' || singleJobInfo?.jobStatus == 'PendingPayment') && (
                        <>
                          <div className="my-3 headinginfo">Completion Note</div>
                          <div className="additionalnote">{singleJobInfo?.completeJobNotes}</div>
                        </>
                      )}
                    {singleJobInfo?.jobStatus == 'Completed' && (
                      <>
                        {singleJobInfo?.expenses?.length > 0 && <div className="my-3 headinginfo">Expenses</div>}
                        <div className="expenses">
                          {singleJobInfo?.expenses?.length > 0 &&
                            singleJobInfo?.expenses?.map((item) => {
                              return (
                                <div
                                  className="expense d-flex justify-content-between"
                                  style={{
                                    border: '1px solid #8492901A',
                                    padding: '10px',
                                    borderRadius: '12px',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <div className="expenseimg d-flex ">
                                    <div className="img mr-2">
                                      <img
                                        src={item?.image?.url}
                                        style={{ width: '40px', height: '40px', borderRadius: '12px' }}
                                      />
                                    </div>
                                    <div className="expenseinfo d-flex flex-column justify-content-center">
                                      <div className="expensename">{item?.name}</div>
                                      <div className="expensename" style={{ color: 'black', fontWeight: '500' }}>
                                        ${Math.abs(item?.price).toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="expenseapp d-flex align-items-center" style={{ color: '#0FF4BD' }}>
                                    {item?.status}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div className="paymentinfo">
                          <div className="paymentheading text-center">Booking Confirmation</div>
                          <div className="timeinfo text-center">
                            {dayjs(singleJobInfo?.paymentDate).format('MMMM DD, YYYY hh:mm A')}
                          </div>
                          <div className="transactioninfo">
                            <div className="transactiondetails d-flex justify-content-between">
                              <div className="trasactionheading">Transaction Id</div>
                              <div className="trasactionheading1">{singleJobInfo?.transactionId}</div>
                            </div>
                            <div className="transactiondetails d-flex justify-content-between">
                              <div className="trasactionheading">Payment Card</div>
                              <div className="trasactionheading1">Card</div>
                            </div>
                            <hr />
                            <div className="transactiondetails d-flex justify-content-between">
                              <div className="trasactionheading">Expenses</div>
                              <div className="trasactionheading1">
                                ${Math.abs(singleJobInfo?.expensesCost).toFixed(2)}
                              </div>
                            </div>
                            <div className="transactiondetails d-flex justify-content-between">
                              <div className="trasactionheading">Contractor Price</div>
                              <div className="trasactionheading1">
                                ${Math.abs(singleJobInfo?.contractorFee + singleJobInfo?.convenienceFee).toFixed(2)}
                              </div>
                            </div>
                            <div className="transactiondetails d-flex justify-content-between">
                              <div className="trasactionheading">{singleJobInfo?.taxDetails?.type}</div>
                              <div className="trasactionheading1">{singleJobInfo?.taxDetails?.totalTax}%</div>
                            </div>
                            <div className="totalamount d-flex  justify-content-between">
                              <div className="totalamount1">Total Amout</div>
                              <div className="price">${Math.abs(singleJobInfo?.totalAmount).toFixed(2)}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {singleJobInfo?.jobStatus == 'Pending' && (
                    <div
                      className="deletejobbtn"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Delete Job Post
                    </div>
                  )}
                  {singleJobInfo?.jobStatus == 'UpComing' && (
                    <div className="upcomingjobbtn">
                      <Button
                        className="cancelbtn"
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="messagebtn"
                        onClick={() => {
                          handleClickMessage();
                        }}
                      >
                        Message
                      </Button>
                    </div>
                  )}
                  {singleJobInfo?.jobStatus == 'OnGoing' && (
                    <div className="upcomingjobbtn">
                      <Button
                        className="cancelbtn"
                        onClick={() => {
                          handleClickMessage();
                        }}
                      >
                        Message
                      </Button>
                      <Button className="messagebtn makepaymentbtn">Make Payment</Button>
                    </div>
                  )}
                  {singleJobInfo?.jobStatus == 'PendingPayment' && (
                    <div className="upcomingjobbtn">
                      <Button
                        className="cancelbtn"
                        onClick={() => {
                          handleClickMessage();
                        }}
                      >
                        Message
                      </Button>
                      <Button
                        className="messagebtn makepaymentbtn makepaymenten"
                        onClick={() => {
                          setMakePayment(true);
                        }}
                      >
                        Make Payment
                      </Button>
                    </div>
                  )}
                  {singleJobInfo?.jobStatus == 'Completed' && (
                    <div className="upcomingjobbtn">
                      {' '}
                      <Button
                        disabled={singleJobInfo?.isReviewSubmittedToContractor || reviewSubmitted}
                        style={{ width: '230px' }}
                        className={
                          singleJobInfo?.isReviewSubmittedToContractor || reviewSubmitted
                            ? 'messagebtn messagebtnd'
                            : 'messagebtn'
                        }
                        onClick={() => {
                          setReviewModal(true);
                        }}
                      >
                        {singleJobInfo?.isReviewSubmittedToContractor || reviewSubmitted ? 'Reviewed' : 'Review'}
                      </Button>
                    </div>
                  )}
                  {singleJobInfo?.jobStatus == 'Cancelled' && (
                    <div className="upcomingjobbtn">
                      <Button
                        className="messagebtn "
                        onClick={() => {
                          navigate('/contactus');
                        }}
                      >
                        Contact Us
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="editjobinfo" style={{ height: '71vh', overflowY: 'scroll' }}>
                    <CreateJob
                      singleJobInfo={singleJobInfo}
                      inDashboard={true}
                      setIsValid={setIsValid}
                      handleCreateJob={handleCreateJob}
                      setJobObj={setJobObj}
                      status={singleJobInfo?.jobStatus}
                      saveLocationList={saveLocationList}
                      flag={flag}
                      setFlag={setFlag}
                      setOpenLocationModal={setOpenLocationModal}
                      address={address}
                      locations={locations}
                      setLocations={setLocations}
                    />
                  </div>
                  <div
                    className={isValid ? 'editsavebtn' : 'editsavebtnd'}
                    onClick={() => {
                      if (singleJobInfo?.jobStatus == 'Pending') {
                        getApproxCost();
                      } else {
                        handleSaveEditJob();
                      }
                    }}
                  >
                    <Button disabled={isValid ? false : true}>Save</Button>
                  </div>
                  {postJobModal && (
                    <Modal
                      centered
                      className="addlocationmodal"
                      closable={false}
                      header={null}
                      footer={null}
                      style={{ top: '', overflow: 'hidden !important' }}
                      open={postJobModal}
                    >
                      <PostJobPopup
                        setPostJobModal={setPostJobModal}
                        postJobModal={postJobModal}
                        approxCost={approxCost}
                        approxCostAlertText={approxCostAlertText}
                        handleCreateJob={handleCreateJob}
                        inEdit={true}
                      />
                    </Modal>
                  )}
                </>
              )}
            </SpinWrapper>
          </>
        ) : (
          <MakePayment
            setMakePayment={setMakePayment}
            breadCrumFunc={breadCrumFunc}
            item={singleJobInfo}
            expenseCost={expenseCost}
            setExpenseCost={setExpenseCost}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
          />
        )}
        <Modal centered header={null} footer={null} open={showPopUp} className="logoutmodal" closable={false}>
          <div className="popup">
            <div
              className="crossicon"
              style={{ right: '1rem', cursor: 'pointer' }}
              onClick={() => {
                handleClose();
              }}
            >
              <img src={crossicon} style={{ width: '30px', height: '30px' }} />
            </div>
            <div className="info">
              <div className="profileimg">
                <img src={singleContractor?.appliedBy?.profileImage?.url} />
              </div>
              <div className="profilename">
                {singleContractor?.appliedBy?.firstName} {singleContractor?.appliedBy?.lastName}
              </div>
              <div className="location">
                <img src={locationgreen} /> {singleContractor?.appliedBy?.city}, {singleContractor?.appliedBy?.province}
              </div>
              <div
                className="ratings d-flex align-items-center justify-content-center"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowRatings(true);
                  setContId(singleContractor?.appliedBy?._id);
                }}
              >
                <div className="starimg">
                  <img src={boldstar} style={{ width: '30px', height: '18px' }} />
                  {Math.abs(singleContractor?.appliedBy?.averageRating).toFixed(2)}
                </div>
                <div className="rating ml-2">{singleContractor?.appliedBy?.totalRating} Ratings</div>
              </div>
            </div>
            <div className="descriptionheading">Description</div>
            <div className="description">{singleContractor?.appliedBy?.description}</div>
            <div className="btns d-flex justify-content-center ">
              <div className="declinebtn" onClick={handleDecline}>
                Decline
              </div>
              <div
                className="messagebtn"
                onClick={() => {
                handleClickPendingMessage();
                }}
              >
                Message
              </div>
              <div
                className="acceptbtn"
                onClick={() => {
                  handleAccept(singleContractor?.appliedBy?._id);
                }}
              >
                Accept
              </div>
            </div>
          </div>
        </Modal>
        {singleJobInfo?.jobStatus == 'UpComing' && (
          <ModalSingle
            content="Are you sure you want to cancel your appointment(s)?"
            setOpenModal={setOpenModal}
            openModal={openModal}
            handleYes={handleYes}
            image={declinere}
            setFeedBack={setFeedBack}
            hasInput="upcoming"
            feedBack={feedBack}
          />
        )}
        {singleJobInfo?.jobStatus == 'Pending' && !isDecline && (
          <ModalSingle
            content="Are you sure you want to delete this job post?"
            setOpenModal={setOpenModal}
            openModal={openModal}
            handleYes={handleYes}
            image={declinere}
            feedBack={feedBack}
            setFeedBack={setFeedBack}
            hasInput="pending"
          />
        )}
        {singleJobInfo?.jobStatus == 'Pending' && isDecline && (
          <ModalSingle
            content="Are you sure you want to decline this contractor profile?"
            setOpenModal={setOpenModal}
            openModal={openModal}
            handleYes={handleYes}
            feedBack={feedBack}
            image={declinere}
            setFeedBack={setFeedBack}
            hasInput="decline"
          />
        )}
        <Modal centered header={null} footer={null} open={reviewModal} className="ratingmodal" closable={false}>
          <img
            className="crossicon"
            src={crossicon}
            onClick={() => {
              setReviewModal(false);
            }}
          />
          <div className="logoutdesc my-3">Share Ratings</div>
          <div className="crossImage">
            <img
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              src={singleJobInfo?.assignedTo?.profileImage?.url}
              className="text-center"
            />
          </div>
          <div className="logoutdesc mt-3">
            {singleJobInfo?.assignedTo?.firstName} {singleJobInfo?.assignedTo?.lastName}
          </div>
          <div className="rating d-flex justify-content-center">
            <Rate
              allowHalf
              value={rate}
              onChange={(e) => {
                setRate(e);
              }}
            />
          </div>
          <div
            className="confirmButtons"
            style={{ justifyContent: 'space-between', display: 'flex', height: '50px', marginTop: '10px' }}
          >
            <Button
              className="yesbtn yes"
              onClick={() => {
                setReviewModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={rate ? 'nobtn' : 'nobtnd nobtn'}
              onClick={() => {
                handleRating();
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>
        <Modal
          centered
          open={viewAll}
          className="privacymodal"
          closable={false}
          width="86%"
          height="86vh"
          header={null}
          footer={null}
          style={{ top: '', overflow: 'hidden !important' }}
        >
          <ViewAll
            contractorList={contractorList}
            setViewAll={setViewAll}
            setShowPopUp={setShowPopUp}
            setSingleContractor={setSingleContractor}
          />
        </Modal>
        {showRatings && <RatingModal setShowRatings={setShowRatings} showRatings={showRatings} singleContId={contId} />}
        {openLocationModal && (
          <Modal
            centered
            className="addlocationmodal"
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={openLocationModal}
          >
            <div ref={wrapperRef}>
              <AddLocation
                setOpenLocationModal={setOpenLocationModal}
                setAddress={setAddress}
                address={address}
                saveLocationList={saveLocationList}
              />
            </div>
          </Modal>
        )}
        {editModal && (
          <Modal
            centered
            className="addlocationmodal"
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={editModal}
          >
            <div>
              <div
                className="crossicon"
                style={{ right: '1rem', cursor: 'pointer',position:"absolute" }}
                onClick={() => {
                  setShowEditModal(false)
                }}
              >
                <img src={crossicon} style={{ width: '30px', height: '30px' }} />
              </div>
              <p> You can edit job only once</p>
              <Button
                className="m-auto d-flex text-center ackbtn"
                style={{
                  backgroundColor: '#0FF4BD',
                  borderColor: '#0FF4BD',
                  color: 'black',
                  height: '42px',
                  width: '78px',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
                onClick={handleEditJob}
              >
                Done
              </Button>
            </div>
          </Modal>
        )}

        {imageView && (
          <PopUpModal
            url={singleJobInfo?.completeJobAttachments[0]?.url}
            setImageView={setImageView}
            imageView={imageView}
          />
        )}
      </>
    </DashBoardWrapper>
  );
};

export default InfoData;
