import React, { useState, useEffect } from 'react';
import { Carousel, message, Modal,Progress } from 'antd';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PrivacyPolicy from './PrivacyPolicy';
import TermsandConditions from './TermsandConditions';
import './termsprivacy.less'
import checkIcon from '../../asset/images/checkIcon.svg';
import emailIcon from '../../asset/images/emailIcon.svg';
import img1 from '../../asset/images/image1.png';
import lockIcon from '../../asset/images/lockIcon.svg';
import logo from '../../asset/images/Logo.svg';
import successicon from '../../asset/images/Successicon.png';
import { setProfile } from '../../redux/slices/login';
import '../SignUp/SignUp.less';

import { signupUser } from '../../api/detailsapi';

const SignUp = () => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [passwordVal, setPasswordVal] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false)
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role")
  const [form] = Form.useForm();
  const images = [img1, img1];
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);
  useEffect(()=>{
     const val = location.pathname.includes('clientsignup') ? '0' : '1';
     localStorage.setItem('role', val);
  },[role])
  const onFinish = (values) => {
    const body = {
      userType: role,
      email: values.email,
      password: values.password,
    };
    doSignUP(body);
  };
  const messageIcon = (
    <div className="signup_input_icon">
      <img src={emailIcon} />
    </div>
  );
  const passwordIcon = (
    <div className="signup_input_icon">
      <img src={lockIcon} />
    </div>
  );

  const emailValidation = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValidEmail = emailRegex.test(value);
    setEmail(isValidEmail);
  };

  const getPasswordStrength = (password) => {
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
    let testCount = 0;
    if (isContainsNumber.test(password)) {
      testCount = testCount + 1;
    }
    if (isContainsLowercase.test(password)) {
      testCount = testCount + 1;
    }
    if (isContainsUppercase.test(password)) {
      testCount = testCount + 1;
    }
    if (isContainsSymbol.test(password)) {
      testCount = testCount + 1;
    }
    return testCount;
  };

  const passwordValidation = (value) => {
    setPasswordVal(value);
    let result = getPasswordStrength(value);
    setPasswordStrength(result);
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
    let isValidPassword =
      isContainsUppercase.test(value) ||
      isContainsLowercase.test(value) ||
      isContainsNumber.test(value) ||
      isContainsSymbol.test(value);
    if (value.length < 8) {
      setPassword(false);
    } else if (isValidPassword) {
      setPassword(false);
    } else {
      setPassword(true);
    }
  };
  const isChecked = () => {
    setChecked(!checked);
  };

  const doSignUP = async (values) => {
    const res = await signupUser(values);
    dispatch(setProfile(res?.data?.data));
    if (res?.status === 200) {
      message.success(res.data.message);
      localStorage.setItem('token', res.data.data.token);
      if (role == '0')
        navigate('/clientprofile');
      if (role == '1')
        navigate('/verifycontractor');
    }
  };

  const createPasswordLabel = (result) => {
    switch (result) {
      case 3:
        return (
          <span className="password_strength_status" style={{ color: 'orange' }}>
            Medium
          </span>
        );
      case 4:
        return (
          <span className="password_strength_status" style={{ color: '#37E17F' }}>
            Strong
          </span>
        );
      default:
        return (
          <span className="password_strength_status" style={{ color: 'red' }}>
            Weak
          </span>
        );
    }
  };

  const passwordStrengthPercentage = (result) => {
    switch (result) {
      case 1:
        return '20%';
      case 2:
        return '25%';
      case 3:
        return '50%';
      case 4:
        return '100%';
      default:
        return '0%';
    }
  };

  return (
    <>
      <div className="signup_container" style={{ overflow: 'hidden' }}>
        <div className="row">
          <div className="col-md-12 col-lg-7 col-xl-6 signup-left-panel">
            <div
              className="left-content"
              style={{
                width: 'auto',
              }}
            >
              <Carousel autoplay={true} effect={'fade'} autoplaySpeed={3000} >
                {images.map((data) => (
                  <div key={data} className='image-wrapper'>
                    <img className=" splashimgstyle" src={data} style={{ objectFit: "cover" }} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-md-12 col-lg-5 col-xl-6 signup_right_panel">
            <div className="rightpanelcontent">
              <div className="signup-logo">
                <img src={logo} />
              </div>
              <div className="signup_heading">
                <div className="signup_data">
                  <h1 className="signup_header">Sign Up</h1>
                  <p className="signup_para">Let&rsquo;s create your account</p>
                </div>
                <div className="signup_end">
                  <div className="form_data">
                    <Form
                      name="normal_login"
                      className="s-form"
                      autoComplete="off"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      form={form}
                    >
                      <Form.Item
                        type="email"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'Please enter a valid email address.',
                          },

                        ]}
                      >
                        <Input
                          prefix={messageIcon}
                          size="large"
                          onChange={(e) => {
                            emailValidation(e.target.value);
                          }}
                          placeholder="Enter Email"
                          className={
                            email ? 'validated_input_field ' : 'input_field'
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        type="password"
                        name="password"
                        rules={[
                          { required: true, message: 'Please enter your Password' },
                          () => ({
                            validator(_, value) {
                              const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                              const isContainsLowercase = /^(?=.*[a-z]).*$/;
                              const isContainsNumber = /^(?=.*[0-9]).*$/;
                              const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                              const isValidLength = /^.{8,20}$/;
                              if (
                                !isContainsNumber.test(value) ||
                                !isContainsLowercase.test(value) ||
                                !isContainsSymbol.test(value) ||
                                !isContainsUppercase.test(value) ||  !isValidLength.test(value)
                              ) {
                                setPassword(false);
                                return Promise.reject(
                                  new Error('Please choose a stronger password which should contain at least one upper case, one lower case, one numerical & one special character. Ensure minimum password length should be 8 characters.')
                                );
                              }
                              setPassword(true);
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          prefix={passwordIcon}
                          size="large"
                          placeholder="Enter Password"
                          onChange={(e) => {
                            passwordValidation(e.target.value);
                          }}
                          className={
                            password ? 'validated_input_field' : 'input_field'
                          }
                        />
                      </Form.Item>
                      {passwordVal && (
                        <Form.Item>
                          <div className="password_strength_content">
                            <div className="password_strength_title">
                              <div style={{ display: 'flex' }}>
                                <span className="password_strength">Strength:</span>
                                {createPasswordLabel(passwordStrength)}
                              </div>
                              <div className="password_strength_percentage">
                                {passwordStrengthPercentage(passwordStrength)}
                              </div>
                            </div>

                            <Progress
                              percent={
                                passwordStrength == 1 || passwordStrength == 2
                                  ? 25
                                  : passwordStrength == 3
                                    ? 60
                                    : passwordStrength == 4
                                      ? 100
                                      : 0
                              }
                              size={[95, 5]}
                              steps={4}
                              strokeColor={
                                passwordStrength == 1 || passwordStrength == 2
                                  ? 'red'
                                  : passwordStrength == 3
                                    ? 'orange'
                                    : passwordStrength == 4
                                      ? '#37E17F'
                                      : 'grey'
                              }
                              showInfo={false}
                              style={{ borderRadius: '1px' }}
                            />
                          </div>
                        </Form.Item>
                      )}
                      <Form.Item
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Confirm Password is Required',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                setConfirmPassword(true);
                                return Promise.resolve();
                              }
                              setConfirmPassword(false);
                              return Promise.reject(new Error('Please make sure confirm password should match with password.'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          prefix={passwordIcon}
                          type="password"
                          size="large"
                          placeholder="Confirm Password"
                          className={
                            confirmPassword
                              ? 'validated_input_field'
                              : 'input_field'
                          }
                        />
                      </Form.Item>
                      <Form.Item name="agreement" valuePropName="checked">
                        <div className="d-flex signup-checkboxd">
                          {checked ? (
                            <img
                              onClick={() => {
                                isChecked();
                              }}
                              src={successicon}
                              style={{ marginBottom: '1.5rem', objectFit: 'contain', paddingRight: "4px" }}

                            />
                          ) : (
                            <img
                              onClick={() => {
                                isChecked();
                              }}
                              src={checkIcon}
                              style={{ marginBottom: '1.5rem', paddingRight: "4px" }}
                            />
                          )}
                          <div className="checkbox_text" >
                            By tapping here you agree to our
                            <a className="text-dark fw-bold" style={{ textDecoration: 'none' }} onClick={() => { setTermsModal(true) }}>
                              <span style={{ color: '#000', fontWeight: '600' }}> Terms and Conditions</span>
                            </a>
                            &
                            <a className="text-dark fw-bold" style={{ textDecoration: 'none' }} onClick={() => { setPrivacyModal(true) }}>
                              <span style={{ color: '#000', fontWeight: '600' }}> Privacy Policy.</span>
                            </a>
                          </div>
                        </div>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className={
                            email && password && confirmPassword && checked
                              ? 'login_form_button '
                              : 'login_form_button_disabled'
                          }
                          style={{ marginTop: '0.43rem' }}
                          disabled={email && password && confirmPassword && checked ? false : true}
                        >
                          Sign Up
                        </Button>
                      </Form.Item>

                    </Form>
                  </div>
                  <div
                    onClick={() => {
                      if (localStorage.getItem('role') == 0)
                        navigate('/clientsignin');
                      else
                        navigate('/contractorsignin');

                    }}
                  >
                    Already have an account?
                    <a className="text-dark fw-bold" style={{ textDecoration: 'none' }}>
                      <span style={{ color: '#000', fontWeight: '600', cursor: "pointer" }}> Sign In</span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={privacyModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <PrivacyPolicy setPrivacyModal={setPrivacyModal} />
      </Modal>
      <Modal
        centered
        open={termsModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <TermsandConditions setTermsModal={setTermsModal} />
      </Modal>
    </>
  );
};

export default SignUp;
