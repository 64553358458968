import { useState } from "react";

const useNetworkStatus = (url, interval = 1500) => {
  const [isOnline, setIsOnline] = useState(true);

  setInterval(() => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection && navigator.onLine) {
      setIsOnline(true)
    } else setIsOnline(false)
  }, interval);

  return isOnline;
};

export default useNetworkStatus;